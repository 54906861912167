import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_1_day_with_task_list from '../../../../../../../common/src/assets/image/example_1_day_with_task_list.png';
import template_options_1_day_with_task_list from '../../../../../../../common/src/assets/image/template_options_1_day_with_task_list.png';

const Documentation = () => {
  const content = (
    <div id="help">
      <p>
        This template generates a calendar showing 1 day per page with a task
        list on the right side of the page. Events will be placed within their
        corresponding time slots. Overlapping events will be shown side-by-side.
      </p>
      <p>
        <Image
          alt=""
          src={example_1_day_with_task_list}
          style={{ width: 868 }}
        />
      </p>
      <h2>Template Settings</h2>
      <p>
        When creating a calendar using the 1 Day/Page with Task List template,
        the following options are provided...
      </p>
      <Image
        alt=""
        src={template_options_1_day_with_task_list}
        style={{ width: 448 }}
      />
      <br />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Start date</strong>
            </td>
            <td>
              The start date can be typed into the box or selected using the
              drop-down arrow. This date will be the left-most column in the
              generated calendar.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Number of days</strong>
            </td>
            <td>
              The total number of days to include in the output. Each day will
              generate to its own worksheet or page.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Timeslots</strong>
            </td>
            <td>
              A timeslot range and interval can be specified. The output will
              only show times between the specified start and end values. Each
              row will correspond to the specified timeslot length, such as 30
              minutes.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Max. columns</strong>
            </td>
            <td>
              Each day in the generated calendar will have up to the specified
              number of sub-columns. These columns are used in cases where there
              are multiple events or tasks that occur in the same timeslot. In
              other words, if you set the maximum number of columns to 6, the
              generated calendar will be able to display up to 6 events or tasks
              occurring in the same time slot.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Max. all-day rows</strong>
            </td>
            <td>
              All-day events and tasks will be shown in an all-day area of the
              generated calendar, which is usually above the timeslot rows. This
              value controls how many events or tasks can be shown in the
              all-day area. Each event or task will take up one row.
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <strong>All-day text behavior</strong>
            </td>
            <td>
              The text behavior of items shown in the all-day area. Options are{' '}
              <strong>Wrap text</strong>, <strong>Don't wrap text</strong>, and{' '}
              <strong>Shrink text to fit</strong>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Item font size</strong>
            </td>
            <td>
              Override the default font size associated with each calendar data
              source.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Task sort order</strong>
            </td>
            <td>
              Sort the tasks in the Task List by either time or calendar data
              source. When sorted by calendar data source, the tasks will be
              effectively grouped based on their associated calendar data
              source.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Time Format</strong>
            </td>
            <td>
              The time format. Supported formats are <strong>12-hour</strong>{' '}
              (8:00 PM), <strong>24-hour</strong> (20:00),{' '}
              <strong>Shortened</strong> (8pm), <strong>Military </strong>
              (2000), and <strong>Numeric </strong>(20.0). An example time is
              shown for each format in the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show timed multiday items in all-day area</strong>
            </td>
            <td>
              When checked, events or starts that have start and end times
              spanning multiple days will be shown in the all-day area instead
              of the timeslot columns.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show start time </strong>
            </td>
            <td>
              When checked, event or task start times will be shown. The
              ordering of times, title, and location values can be specified in
              the <Link to="/Documentation/Options">PrintableCal options</Link>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show end time</strong>
            </td>
            <td>When checked, event or task end times will be shown.</td>
          </tr>
          <tr>
            <td>
              <strong>Show location</strong>
            </td>
            <td>When checked, event or task locations will be shown.</td>
          </tr>
          <tr>
            <td>
              <strong>Show location on separate line</strong>
            </td>
            <td>
              When checked, event or task locations will be shown on a separate
              line, below the title and above the description.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show description</strong>
            </td>
            <td>
              When checked, descriptions will be included in event and task
              blocks (if enough space is available).
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show blank line above description</strong>
            </td>
            <td>
              When checked, a blank line will be shown above the description, to
              help differentiate the description from the title and location
              values.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Use bold text for item titles</strong>
            </td>
            <td>
              When checked, item titles will be in bold text, to help
              differentiate from the location and description values.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show overdue tasks </strong>
            </td>
            <td>
              When checked, any tasks that will be overdue on the specified date
              will be shown, even if they were scheduled to be due on an earlier
              date.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show completed tasks </strong>
            </td>
            <td>
              When checked, any completed tasks that were due on the specified
              date will be shown.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show tasks that have no due date </strong>
            </td>
            <td>
              When checked, tasks that don't have a specific due date will also
              be listed.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Day-1-Day-Page-with-Task-List"
      commentsId="commentsplus_post_1187_1628"
      title="1 Day/Page with Task List Template"
      description="Generate a calendar showing 1 day per page with a task list on the right side of the page. Events will be placed within their corresponding time slots. Overlapping events will be shown side-by-side."
      keywords="day template, task list"
      content={content}
    />
  );
};

export default Documentation;
